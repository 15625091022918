
import service from "./service";

import { hsdApi } from './baseApi.js'

export function Get(url, params = {}) {
  let hsd_url = url, keys = Object.keys(params);
  if (keys.length > 0) {
    for (let i = 0; i < keys.length; i++) {
      hsd_url = i == 0 ? `${hsd_url}?` : hsd_url;
      let bra = i < keys.length - 1 ? "&" : "";
      hsd_url = `${hsd_url}${keys[i]}=${params[keys[i]]}${bra}`
    }
  }
  
  return service.get(hsd_url).then(res => res && res.data);
}

export function Post(url, params = {}) {
  return service.post(url, params).then(res => res && res.data);
}
//好师到接口

//获取年级
export const getHsdGrade = params => {
  return Get(`${hsdApi}/api/v1/base/grade`)
};
//获取科目
export const getHsdCulum = params => {
  return Get(`${hsdApi}/api/v1/base/curriculum`, params)
};

//获取公告列表 
export const getHsdNotices = params => {
  return Post(`${hsdApi}/api/v1/base/notice_list`, params)
};
//获取公告新增状态
export const getHsdNoticesType = params => {
  return Get(`${hsdApi}/api/v1/base/new_notice_check`, params)
}
//获取公告列表 
export const getHsdrechargeOrder = params => {
  return Get(`${hsdApi}/api/v1/user/query_recharge_order`, params)
};
//获取公告点赞
export const getHsdLikeNotice = params => {
  return Get(`${hsdApi}/api/v1/base//like_notice`, params)
};
//手機驗證碼
export const getHsdSmsCode = params => {
  return Post(`${hsdApi}/api/v1/user/sms_code`, params)
};
//手機號登录
export const getHsdUserLogin = params => {
  return Post(`${hsdApi}/api/v1/user/login_by_user`, params)
};
//手機號註冊
export const getHsdUserRegster = params => {
  return Post(`${hsdApi}/api/v1/user/reg_by_mobile`, params)
};
//未登录 忘记密码修改密码 
export const getHsdForgetPassword = params => {
  return Post(`${hsdApi}/api/v1/user/forget_password_by_mobile`, params)
};
//已登录 修改密码 
export const getHsdupdatePassword = params => {
  return Post(`${hsdApi}/api/v1/user/update_password`, params)
};
//用户登出
export const getHsdLoginOut = params => {
  return Get(`${hsdApi}/api/v1/user/login_out`, params)
};
//获取用户基本信息
export const getHsdUserInfo = params => {
  return Get(`${hsdApi}/api/v1/user/get_user_base_info`, params)
};

// /api/v1/user/class_order_by_day`
export const getClassByDay = params => {
  return Get(`${hsdApi}/api/v1/user/class_order_by_day`, params)
};

//課程規劃定時刷新 
export const getLearnPlanByDay = params => {
  return Get(`${hsdApi}/api/v1/user/learn_plan_by_day`, params)
};

//修改用户头像
export const getHsdUserPhoto = params => {
  return Post(`${hsdApi}/api/v1/user/mod_Photo`, params)
};

//修改学生用户信息
export const getUpdateUserInfo = params => {
  return Post(`${hsdApi}/api/v1/user/user_info_mod`, params)
};

//get学生用户信息
export const getSUserInfo = params => {
  return Get(`${hsdApi}/api/v1/user/get_user_info`, params)
};
//充值产品条目
export const getRechargeList = params => {
  return Get(`${hsdApi}/api/v1/user/recharge_shelf_list`, params)
};
//充值优惠券有效性检查
export const getCheckCoupon = params => {
  return Get(`${hsdApi}/api/v1/user/check_coupon`, params)
};
//充值点数初始化订单
export const getInitRecharge = (params = {}) => {
  return Get(`${hsdApi}/api/v1/user/init_recharge_order`, params)
};
//充值点数生成订单
export const getCreateRecharge = (params = {}) => {
  return Get(`${hsdApi}/api/v1/user/create_recharge_order`, params)
};
//充值支付生成订单
export const getPayforRecharge = (params = {}) => {
  return Get(`${hsdApi}/api/v1/user/payFor_recharge_order`, params)
};
//點數记录变动
export const getUserPointLog = params => {
  return Post(`${hsdApi}/api/v1/user/user_point_change_log`, params)
};
//学生课程订单记录
export const getStuOrderList = params => {
  return Get(`${hsdApi}/api/v1/user/class_order_list`, params);
};
//学生评价星数
export const getOrderEditEffect = params => {
  return Post(`${hsdApi}/api/v1/user/class_order_edit_effect`, params)
};
//學生成績記錄列表 
export const getAcademicList = params => {
  return Post(`${hsdApi}/api/v1/user/academic_record_list`, params)
};
//學生錄影列表
export const getUrlList = params => {
  return Post(`${hsdApi}/api/v1/user/playback_url_list`, params)
};
//即將開課  
export const getLastClassOrder = params => {
  return Get(`${hsdApi}/api/v1/user/last_class_order`, params);
};
//学生课前测验 
export const getUserBeforeTest = (params) => {
  return Post(`${hsdApi}/api/v1/user/learn_plan_before_test`, params);
};
//學生上傳作業答案
export const getStuUplaodAnswer = params => {
  return Post(`${hsdApi}/api/v1/user/upload_answer`, params);
};
//課後測驗記錄
export const getStuAfterTest = params => {
  return Post(`${hsdApi}/api/v1/user/learn_plan_after_test`, params);
};
//行事历接口-新
export const getUserClassOrderNew = params => {
  return Get(`${hsdApi}/api/v1/user/learn_plan_list`, params);
};
//學生學習歷程 
export const getUserLearnTestLog = params => {
  return Post(`${hsdApi}/api/v1/user/learn_test_log`, params);
};
//教师接口！

//获取服务器时间
export const getNowServerTime = params => {
  return Get(`${hsdApi}/api/v1/base/now_time`, params);
};
//获取老师单日课程接口
export const getTeaClassByDay = params => {
  return Get(`${hsdApi}/api/v1/teacher/class_order_by_day`, params);
};
//教师登录
export const getTeacherLogin = params => {
  return Post(`${hsdApi}/api/v1/teacher/login_by_username`, params)
};
//教师登出
export const getTeacherLogout = params => {
  return Get(`${hsdApi}/api/v1/teacher/login_out`, params);
};
//教师基本信息
export const getTeacherBaseinfo = params => {
  return Get(`${hsdApi}/api/v1/teacher/get_teacher_base_info`, params);
};
//教师修改上传头像
export const getTeacherUpdatePhoto = params => {
  return Post(`${hsdApi}/api/v1/teacher/teacher_photo_mod`, params);
};
//获取教师擅长科目
export const getTeacherSubject = params => {
  return Get(`${hsdApi}/api/v1/teacher/get_teacher_subject_info`, params);
};
//教师修改密码
export const getTeacherPassword = params => {
  return Post(`${hsdApi}/api/v1/teacher/teacher_password_mod`, params);
};
//教师资料信息修改
export const getTeacherMod = params => {
  return Post(`${hsdApi}/api/v1/teacher/teacher_info_mod`, params);
};
//获取教师资料信息
export const getTeacherUserInfo = params => {
  return Get(`${hsdApi}/api/v1/teacher/get_teacher_info`, params);
};
//教师擅长科目修改
export const getTeacherSubjectUserInfo = params => {
  return Post(`${hsdApi}/api/v1/teacher/teacher_subject_info_mod`, params);
};
//教师排课
export const saveTeacherScheduleList = params => {
  return Post(`${hsdApi}/api/v1/teacher/save_schedule_list`, params);
};
//获取教师排课time list
export const getTeacherScheduleList = params => {
  return Get(`${hsdApi}/api/v1/teacher/get_schedule_list`, params);
};
//获取资料列表
export const getTeacherFileList = params => {
  return Post(`${hsdApi}/api/v1/teacher/file_list`, params);
};
//获取视频列表
export const getTeacherVideoList = params => {
  return Post(`${hsdApi}/api/v1/teacher/video_list`, params);
};
//老师课程订单
export const getTeacherClassOrder = params => {
  return Get(`${hsdApi}/api/v1/teacher/teacher_class_order_list`, params);
};
//上課打卡
export const getTeacherSignIn = params => {
  return Get(`${hsdApi}/api/v1/teacher/teacher_sign_in`, params);
};
//下課打卡
export const getTeacherSignOut = params => {
  return Get(`${hsdApi}/api/v1/teacher/teacher_sign_out`, params);
};
//老師編寫進度 
export const getEditProgress = params => {
  return Post(`${hsdApi}/api/v1/teacher/edit_expected_progress`, params);
};
//填寫回報
export const getEditTeacherReturn = params => {
  return Post(`${hsdApi}/api/v1/teacher/user_teacher_return`, params);
};
//即將開課  
export const getTeaLastClassOrder = params => {
  return Get(`${hsdApi}/api/v1/teacher/last_class_order`, params);
};
//上課錄影列表 
export const getTeaPlayurlList = params => {
  return Post(`${hsdApi}/api/v1/teacher/playback_url_list`, params);
};
//老师薪资结算 
export const getPayIncomeList = params => {
  return Get(`${hsdApi}/api/v1//teacher/income_list`, params);
};


//学习顾问接口！
//登陆
export const getStaffLogin = params => {
  return Post(`${hsdApi}/api/v1/staff/login_by_username`, params);
};
//获取基本信息
export const getStaffBaseInfo = params => {
  return Get(`${hsdApi}/api/v1/staff/get_staff_base_info`, params);
};
//老师登出
export const getStaffLoginout = params => {
  return Get(`${hsdApi}/api/v1/staff/login_out`, params);
};
//修改密码
export const getStaffPasswordmod = params => {
  return Post(`${hsdApi}/api/v1/staff/staff_password_mod`, params);
};
//获取学生列表
export const getStudentList = params => {
  return Post(`${hsdApi}/api/v1/staff/student_list`, params);
};
//新增读书规划
export const getAddReading = params => {
  return Post(`${hsdApi}/api/v1/staff/add_reading`, params);
};
//获取读书规划
export const getReadList = params => {
  return Post(`${hsdApi}/api/v1/staff/reading_list`, params);
};
//编辑读书规划
export const getEditRead = params => {
  return Post(`${hsdApi}/api/v1/staff/edit_reading`, params);
};
//新增联络信息
export const getAddContact = params => {
  return Post(`${hsdApi}/api/v1/staff/add_contact`, params);
};
//联络信息列表
export const getContactList = params => {
  return Post(`${hsdApi}/api/v1/staff/contact_list`, params);
};
//编辑联络信息
export const getEidtContactList = params => {
  return Post(`${hsdApi}/api/v1/staff/edit_contact`, params);
};
//学生开课
export const getCreateClassOrder = params => {
  return Post(`${hsdApi}/api/v1/staff/create_class_order`, params);
};
//过滤老师列表
export const getTeacherFilter = params => {
  return Post(`${hsdApi}/api/v1/teacher/teacher_filter`, params);
};
//上課資料紀錄未填寫
export const getCheckEditList = params => {
  return Post(`${hsdApi}/api/v1/staff/class_check_edit_list`, params);
};
//前15分钟未打卡 
export const getTeacherDisSignIn = params => {
  return Get(`${hsdApi}/api/v1/staff/teacher_dis_sign_in`, params);
};
//获取老师列表
export const getManagerTeaList = params => {
  return Post(`${hsdApi}/api/v1/staff/teacher_list`, params);
};
//获取老师列表
export const getCancelClassOrder = params => {
  return Get(`${hsdApi}/api/v1/staff/cancel_class_order_by_id`, params);
};
//获取学生上课记录
export const getClassOrderList = params => {
  return Post(`${hsdApi}/api/v1/staff/user_class_order_list`, params);
};
//取消学生课程
export const getCancleClassById = params => {
  return Get(`${hsdApi}/api/v1/staff/cancel_class_order_by_id`, params);
};
//填写学生上课记录
export const getEditClassLog = params => {
  return Post(`${hsdApi}/api/v1/staff/edit_class_Log`, params);
};
//获取老师列表 
export const getTeaClassOrderList = params => {
  return Post(`${hsdApi}/api/v1/staff/teacher_class_order_list`, params);
};
//老师回报信息审查 
export const getApprovalTeaReturn = params => {
  return Post(`${hsdApi}/api/v1/staff/approval_teacher_return`, params);
};
//学习顾问获取老师上课记录
export const getTeaScheduleList = params => {
  return Get(`${hsdApi}/api/v1/staff/teacher_schedule_list`, params);
};
//学习顾问编辑老师上课记录
export const saveTeaScheduleList = params => {
  return Post(`${hsdApi}/api/v1/staff/save_teacher_schedule_list`, params);
};

//學生上課成績 
export const getStudentAcademic = params => {
  return Post(`${hsdApi}/api/v1/staff/academic_record_list`, params);
};
//更新作業成績
export const getEditAcademic = params => {
  return Post(`${hsdApi}/api/v1/staff/edit_class_Log`, params);
};

//更新作業成績
export const getEditPlayUrl = params => {
  return Post(`${hsdApi}/api/v1/staff/edit_playback_url`, params);
};
//更新狀態上課
export const getEditClassStatus = params => {
  return Post(`${hsdApi}/api/v1/staff/edit_class_status`, params);
};

// 课程管理接口
//获取置顶接口
export const getTopCourseList = params => {
  return Get(`${hsdApi}/api/v1/base/top_courses`, params);
};
//獲取全部課程分類
export const getCourseTypeList = params => {
  return Get(`${hsdApi}/api/v1/base/course_type_list`, params);
};

//获取课程版本号
export const getCourseVersion = params => {
  return Get(`${hsdApi}/api/v1/base/course_version`, params);
};
//获取指定分类课程 api/v1/base/
export const getFilterCourseList = params => {
  return Post(`${hsdApi}/api/v1/base/courses_list_by_filter`, params);
};

//获取单元列表 unit_list_by_cours
export const getUnitCourseList = params => {
  return Post(`${hsdApi}/api/v1/base/unit_list_by_cours`, params);
};

// 组卷接口

// 获取产品列表
export const getProductList = params => {
  return Get(`${hsdApi}/api/v1/test_base/product`, params);
};
//获取对应产品的版本
export const getProductVerssion = params => {
  return Get(`${hsdApi}/api/v1/test_base/version`, params);
};
//获取产品对应的科目  
// params
// product:JC
export const getProductSubject = params => {
  return Get(`${hsdApi}/api/v1/test_base/subject`, params);
};
//获取对应产品的类别 
export const getProductClass = params => {
  return Get(`${hsdApi}/api/v1/test_base/class`, params);
};
//获取对应产品的单元
export const getProducUnit = params => {
  return Get(`${hsdApi}/api/v1/test_base/unit`, params);
};
//获取对应产品的主题
/**
 * 
 * @param {uint_id} params 
 */
export const getProducTopic = params => {
  return Get(`${hsdApi}/api/v1/test_base/topic`, params);
};
/**
 * 
 * @param {
 * class_id
 * product=JC
 * } params 
 */
//通过年级获取主题
export const getProducTopicByClass = params => {
  return Get(`${hsdApi}/api/v1/test_base/topic_by_class`, params);
};
//开课组卷
export const createCourseBefore = params => {
  return Post(`${hsdApi}/api/v1/test_paper/create_course_before`, params);
};
//关课组卷（课程的结束总测试课组卷）
export const createCourseAfter = params => {
  return Post(`${hsdApi}/api/v1/test_paper/create_course_after`, params);
};
//单元组卷（单元测试组卷）
export const createUnitTest = params => {
  return Post(`${hsdApi}/api/v1/test_paper/create_unit_test`, params);
};
/**
 * @param {
 * product
 * topic_list
 * difficult
 * question_amount
 * subject_id
 * token
 * }
 */
//用户自主组卷
export const createUserTest = params => {
  return Post(`${hsdApi}/api/v1/test_paper/create_user_test`, params);
};
//购买课程
/**
 * 
 * @param {*course_id} params 
 */
export const createCourseOrder = params => {
  return Get(`${hsdApi}/api/v1/user/create_course_order`, params);
};
//检查课程是否已购
/**
 * 
 * @param {course_id} params 
 */
export const checkCourseOrder = params => {
  return Get(`${hsdApi}/api/v1/user/check_course_order`, params);
};
//组卷测试列表
/**
 * 
 */
export const getTestList = params => {
  return Post(`${hsdApi}/api/v1/experience/experience_test_papers`, params);
};
//组卷选课测试
/**
 * @param product
 * @param topic_list
 * @param difficult 
 * @param question_amount
 * @param subject_id
 */
export const createTestPapers = params => {
  return Post(`${hsdApi}/api/v1/experience/create_test_papers`, params);
};

//学生申请选课测试
export const getUpdateExperienceInfo = params => {
  return Post(`${hsdApi}/api/v1/user/update_experience_info`, params);
};

//检查测试状态
export const checkExperienceStatus = params => {
  return Get(`${hsdApi}/api/v1/user/check_experience_status`, params);
};
//获取适合学生的选课测试列表 
export const getUserExperienceTest = params => {
  return Post(`${hsdApi}/api/v1/user/user_experience_test_papers`, params);
};
//開課信息緩存
export const getOpenExperienceTest = params => {
  return Post(`${hsdApi}/api/v1/user/open_experience_test`, params);
};
//學生開門測試結果列表 
export const getExperienceTest = params => {
  return Post(`${hsdApi}/api/v1/experience/user_test_list`, params);
};
//用户测试结果列表返回
export const getTestListByUser = params => {
  return Post(`${hsdApi}/api/v1/test/user_test_list`, params);
};
//學習顧問單元組卷 
export const createUserTestUnit = params => {
  return Post(`${hsdApi}/api/v1/staff/create_user_unit_test_paper`, params);
};
//开门测试后学生排课 
export const getAddClassOrderFree = params => {
  return Post(`${hsdApi}/api/v1/staff/add_class_order_by_free`, params);
};
//用户错题再练初始化
export const getUserWrongTestAgain = params => {
  return Get(`${hsdApi}/api/v1/user/wrong_test_again`, params);
}
//学习顾问获取学生行事历
export const getClassPlanOnManager = params => {
  return Get(`${hsdApi}/api/v1/staff/learn_plan_list`, params);
};
//新增学习计划
export const getAddClassOnManager = params => {
  return Post(`${hsdApi}/api/v1/staff/learn_plan/add_learn_plan`, params);
};
//課前預習讀書  
export const getAddReadOnManager = params => {
  return Post(`${hsdApi}/api/v1/staff/learn_plan/add_reading`, params);
};
//一對一開課
export const getAddOrderOnManager = params => {
  return Post(`${hsdApi}/api/v1/staff/learn_plan/add_class_order`, params);
};
//課後測驗--學習顧問用 
export const getCreateTestAfterOnManager = params => {
  return Post(`${hsdApi}/api/v1/staff/learn_plan/create_test_after`, params);
};
//課前測驗--學習顧問用 
export const getCreateTestBeforeOnManager = params => {
  return Post(`${hsdApi}/api/v1/staff/learn_plan/create_test_before`, params);
};
//課後作業--學習顧問上傳
export const getUpdateOnManager = (params) => {
  return Post(`${hsdApi}/api/v1/staff/learn_plan/upload_subject`, params);
};
//下載學生作業答題
export const getLearnPlanAnswer = params => {
  return Get(`${hsdApi}/api/v1/learn_plan/get_answer`, params);
};
//下載學生作業題目
export const getLearnPlanWork = params => {
  return Get(`${hsdApi}/api/v1/learn_plan/get_subject`, params);
};
//填寫課後作業成績
export const getAddHomeworkAcademic = (params) => {
  return Post(`${hsdApi}/api/v1/staff/learn_plan/add_homework_academic`, params);
};
//獲取學生學習規劃列表 
export const getStuLearnPlanList = (params) => {
  return Post(`${hsdApi}/api/v1/staff/learn_plan/learn_plan_list`, params);
};
//關閉課程
export const getClosedLearnPlan = (params) => {
  return Get(`${hsdApi}/api/v1/staff/learn_plan/closed`, params);
};
//刪除學生課前預習 
export const getDeleteReading = (params) => {
  return Get(`${hsdApi}/api/v1/staff/learn_plan/del_reading`, params);
}; 
//录入周考段考成绩
export const getStuTestScore = (params) => {
  return Post(`${hsdApi}/api/v1/staff/register_test_score`, params);
};
//周考段考成绩列表
export const getStuTestScoreList= (params) => {
  return Post(`${hsdApi}/api/v1/staff/test_score_list`, params);
};
//編輯學校成績
export const getEditStuTestScore= (params) => {
  return Post(`${hsdApi}/api/v1/staff/edit_test_score`, params);
};
//删除學校成績
export const getDelStuTestScore= (params) => {
  return Get(`${hsdApi}/api/v1/staff/del_test_score`, params);
};
import Vue from "vue";
import App from "./App.vue";
import store from "./store"; //引入store
import router from "./router";

import echarts from "echarts";
Vue.prototype.$echarts = echarts;

import VueI18n from "vue-i18n"; //国际化组件
// import util from './common/js/util.js'
import global from "./components/global.vue";

// axios
import service from "@/api/service";
Vue.prototype.$http = service;

import Router from "vue-router";
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

// css样式还是需要全部引入
import "element-ui/lib/theme-chalk/index.css";
import element from "element-ui";
import locale from "element-ui/lib/locale/lang/zh-CN";
// import './element-default.scss'
Vue.use(element, { locale });

import animated from "animate.css";
Vue.use(animated);

Vue.prototype.COMMON = global;
Vue.use(VueI18n); // 通过插件的形式挂载

const NavbarStore = {
  showNavbar: false,
};

const i18n = new VueI18n({
  locale: "zh-CN", // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    "zh-CN": require("./common/lang/zh.js"), // 中文语言包
    "en-US": require("./common/lang/en.js"), // 英文语言包
    ko: require("./common/lang/ko.js"), //韩文语言包
  },
});

Vue.mixin({
  data() {
    return {
      NavbarStore,
    };
  },
});

new Vue({
  router,
  i18n,
  store, //使用store
  render: (h) => h(App),
}).$mount("#app");

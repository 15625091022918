
import axios from "axios";
import router from "@/router";
import { Message } from 'element-ui'

let isLoginOut = false, pathName = window.location.pathname;

const commmonHeader = {
  'Accept': 'application/json',
}

let fetchApiHeader = { 'Content-Type': 'application/x-www-form-urlencoded' }

let form_config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
// 创建axios实例
const service = axios.create({
  // baseURL: baseUrl, // api的base_url
  timeout: 30000, // 请求超时时间
  changeOrigin: true,
  Accept: '*/*'
})
service.interceptors.request.use(
  config => {
    if (config.data instanceof FormData) { //文件类,文件类不需要stringify
      Object.assign(config.headers, form_config.headers);
    }
    // if (config.method.toLowerCase() == "post") {
    //   let newdata = config.data;
    //   if (config.data instanceof FormData) {
    //     Object.assign(config.headers, commmonHeader, formApiHeader);
    //   } else {
    //     newdata = (typeof newdata == 'object' && Object.keys(newdata).length >= 0) ? newdata : {};
    //     newdata = {
    //       ...newdata,
    //     }
    //     Object.assign(config.headers, commmonHeader, fetchApiHeader);
    //     // newdata = qs.stringify(newdata);
    //   }
    //   config.data = newdata;
    // }
    if (config.url.indexOf('/staff/') >= 0) {
      let token = localStorage.getItem("managerToken");
      if (token) {
        config.headers.common["m_token"] = token;
      }
    } else {
      let storage = localStorage.getItem("token")
      if (storage) {
        let objLocal = JSON.parse(storage);
        // if(config.url.includes("/api/v1/user/learn_plan_before_test")) alert(objLocal.token)
        if (objLocal.isStudent) {
          config.headers.common["token"] = objLocal.token;
        } else {
          config.headers.common["t_token"] = objLocal.token;
        }
      }
    }
    return config;
    // 登录不需要传token,看一下自己登录的接口包含哪个特殊字段

  },
  err => {
    return Promise.reject(err);
  }
);

// respone拦截器
service.interceptors.response.use(
  response => {
    const response_data = response.data
    if (response_data && response_data.hasOwnProperty('success') && response_data.success !== 1) {
      Message({
        message: response_data.msg,
        type: 'error',
        duration: 3 * 1000
      })
    }
    if (response.status < 200 || response.status >= 300) {
      Message({
        message: response.statusText,
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(response)
    }
    return response
  },
  error => {
    if (error.response && error.response.status == 401) {
      if (!isLoginOut) {
        Message({
          type: 'error',
          message: '登錄已過期，需重新登錄'
        })
        if (pathName.indexOf('manager') >= 0) {
          router.push('/managerLogin');
          let t = localStorage.getItem('managerToken')
          if (t) {
            localStorage.removeItem('managerToken');
          }
        } else {
          router.push('/login');
          let t = localStorage.getItem('token');
          if (t) {
            localStorage.removeItem('token');
          }
        }
        isLoginOut = true;
      }
      return Promise.reject(error) // 返回接口返回的错误信息
    }
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export default service;

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        { path: '*', redirect: '/index' },
        {
            path: "/index",
            redirect: "/home",
            name: "page_center_index",
            component: resolve => require(["./components/home/page_center_index"], resolve),
            children: [
                {
                    path: "/home",
                    name: "hsd_index",
                    component: resolve => require(["./components/hsd_index"], resolve),
                },
                {
                    path: "/login",
                    name: "page_member_login",
                    component: resolve => require(["./components/home/page_member_login"], resolve),
                },
                {
                    path: "/iTeacher",
                    name: "page_iteacher",
                    component: resolve => require(["./components/home/page_iteacher"], resolve),
                },
                {
                    path: "/iTutor",
                    name: "page_itutor",
                    component: resolve => require(["./components/home/page_itutor"], resolve),
                },
                {
                    path: "/classInfor",
                    name: "page_class_infor",
                    component: resolve => require(["./components/home/page_class_infor"], resolve),
                },
            ]
        },
        {
            path: "/student",
            name: "member_center_page",
            component: resolve => require(["./components/student/member_center_page"], resolve),
        },
        {
            path: "/teacher",
            name: "teacher_center_page",
            component: resolve => require(["./components/teacher/teacher_center_page"], resolve),
        },
        {
            path: "/payChecking",
            name: "member_buy_check_page",
            component: resolve => require(["./components/payCheck/member_buy_check_page"], resolve),
        },
        {
            path: "/payFor_recharge",
            name: "member_recharge_page",
            component: resolve => require(["./components/payCheck/member_recharge_page"], resolve),
        },
        {
            path: "/managerLogin",
            name: "tutor_manager_login",
            component: resolve => require(["./components/manager/page_manager_login"], resolve),
        },
        {
            path: "/manager",
            redirect: "/managerIndex",
            name: "manager_index_page",
            component: resolve => require(["./components/manager/index"], resolve),
            children: [
                {
                    path: "/managerIndex",
                    name: "administrator_center_page",
                    component: resolve => require(["./components/manager/manager_center_page"], resolve),
                },
                {
                    path: "/managerStudent",
                    name: "manager_student_page",
                    component: resolve => require(["./components/manager/manager_student_page"], resolve),
                },
                {
                    path: "/managerTeacher",
                    name: "manager_teacher_page",
                    component: resolve => require(["./components/manager/manager_teacher_page"], resolve),
                },
                {
                    path: "/managerLeason",
                    name: "manager_leason",
                    component: resolve => require(["./components/manager/manager_leason"], resolve),
                },
            ]
        },
        {
            path: "/leason",
            name: "leason",
            component: resolve => require(["./components/leason/leasonManager"], resolve),           
        },
        
        {
            path: "/leasonDetail",
            name: "leasonDetail",
            component: resolve => require(["./components/leason/leasonDetail"], resolve),
        },    
        {
            path: "/question",
            name: "questionIndex",
            component: resolve => require(["./components/question/combination"], resolve),
        },
        {
            path: "/test",
        }
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});
router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
    if (to.meta.title) {//判断是否有标题
        document.title = to.meta.title
    }
    if (to.path == '/m_appTest') {
        console.log(to)
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        meta.content = to.meta.content;
        meta.name = to.meta.name;
        head[0].appendChild(meta)
    }
    next()//执行进入路由，如果不写就不会进入目标页
})
export default router



<script type="text/javascript">
    // 定义一些公共的属性和方法
    const userType=1;

    export default {
        userType
    }
</script>

import Vue from "vue";
import Vuex from "vuex";
import { getHsdGrade, getProductVerssion, getProductSubject } from "../api/api";
Vue.use(Vuex);
const state = {
  topNavId: "home",
  queryReachargeOrder: "", //最新订单编号
  isStudentLogin: false, //学生登录
  isTeacherLogin: false, //教师登录
  isManagerLogin: false, //管理员登录
  isExperince: false, //学习体验弹窗打开
  hsdGradeList: [], //年级列表
  hsdVersionList: [], //版本列表
  testSubjectList: [],
  noticeTipsOpen: 0, //管理員提示彈窗
  classCheckEditList: [], //客服區未填寫資料
  testPaperData: {}
};
const getters = {
  //实时监听state值的变化(最新状态)
  getLoginStatus(state) {
    return state.isStudentLogin;
  }
};
const mutations = {
  setLocalTestPaper(state, Data) {
    state.testPaperData = Data;
    localStorage.setItem("test_paper_id", Data.test_paper_id);
    localStorage.setItem("user_test_id", Data.user_test_id);
  },
  changeTopNavId(state, S) {
    state.topNavId = S;
  },
  changeStudentLogin(state, L_STATUS) {
    state.isStudentLogin = L_STATUS;
  },
  changeTeacherLogin(state, T_STATUS) {
    state.isTeacherLogin = T_STATUS;
  },
  changeManagerLogin(state, M_STATUS) {
    state.isManagerLogin = M_STATUS;
  },
  changeExperienceStatus(state, E_STATUS) {
    state.isExperince = E_STATUS;
  },
  setHsdGrades(state, LIST) {
    state.hsdGradeList = LIST;
  },
  setHsdVersion(state, LIST) {
    state.hsdVersionList = LIST;
  },
  toggleManagerNotice(state, ikey) {
    state.noticeTipsOpen = ikey;
  },
  setManagerNotice(state, L) {
    state.classCheckEditList = L;
  },
  setRechargeOrder(state, IDS) {
    state.queryReachargeOrder = IDS;
  },
  setHsdTextSubject(state, L) {
    state.testSubjectList = L;
  }
};
const actions = {
  sChangeLoginStatus(context, NUM) {
    //自定义触发mutations里函数的方法，context与store 实例具有相同方法和属性
    context.commit("changeLoginStatue", NUM);
  },
  mChangeStep(context, NUM) {
    //同上注释
    context.commit("changeStep", NUM);
  },
  mChangeLogin(context, NUM) {
    context.commit("changeLoginStatus", NUM);
  },
  sGetLocalstorage(context, val) {
    let s_t_token = window.localStorage.getItem("token"),
      m_token = window.localStorage.getItem("managerToken");
    if (s_t_token) {
      let storagObj = JSON.parse(s_t_token);
      if (storagObj.isStudent) {
        context.commit("changeStudentLogin", true);
      } else {
        context.commit("changeTeacherLogin", true);
      }
    }
    if (m_token) {
      context.commit("changeManagerLogin", status);
    }
    if (val){
      alert("调试区");
    }
  },
  async getHsdGrades(context) {
    const res = await getHsdGrade();
    if (res.code == 200) {
      context.commit("setHsdGrades", res.data);
    }
  },
  //获取版本
  async getVersionData(context, product) {
    const res = await getProductVerssion({ product });
    if (res.success == 1) {
      context.commit("setHsdVersion", res.data);
    }
  },
  //測試科目列表
  async getSubjectData(context, product) {
    const res = await getProductSubject({ product });
    if (res.success == 1) {
      context.commit("setHsdTextSubject", res.data);
    }
  }
};
const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});
export default store;

<template>
  <div id="material-kit" class="html-bg clientStyle">
    <div :class="{ 'nav-open': NavbarStore.showNavbar }">
      <router-view name="header" />
      <div>
        <router-view />
      </div>
      <router-view name="footer" />
    </div>
    <footer-hsd></footer-hsd>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import footerHsd from "./components/layout/footerHsd";
export default {
  name: "app",
  data() {
    return {
      product: "JC",
    };
  },
  components: { footerHsd },
  methods: {
    ...mapActions(["getHsdGrades", "getVersionData", "getSubjectData"]),
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    this.getHsdGrades();
    this.getVersionData(this.product);
    this.getSubjectData(this.product);
  },
};
</script>
<style scoped>
</style>

<template>
  <div>
    <!-- ... footer 聯絡資訊、追蹤我們 招募人才 版權說明 條款 聲明 黑色區塊... -->
    <div class="footer_black_container">
      <div class="footer_black">
        <div class="footer_black_top">
          <div class="footer_black_follow_job">
            <div class="footer_black_follow">
              <div class="footer_black_follow_title">追蹤我們</div>
              <div class="footer_black_follow_icon">
                <a
                  href="https://www.facebook.com/myteacher12345"
                  target="_blank"
                >
                  <img alt="粉絲團" src="../../assets/image/follow_icon_1.png" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCC42p8RfYnd_sACu_KoVETQ/"
                  target="_blank"
                >
                  <img alt="影音頻道" src="../../assets/image/follow_icon_2.png" />
                </a>
              </div>
            </div>
            <div class="footer_black_job">
              <div class="footer_black_job_title">招募人才</div>
              <div class="footer_black_job_icon">
                <a
                  target="_blank"
                  href="https://www.104.com.tw/company/1a2x6biida"
                >
                  <img alt="人才招募" src="../../assets/image/job_icon.png" />
                </a>
              </div>
            </div>
            <div class="footer_black_prepare">
              <div class="footer_black_prepare_title">課前準備</div>
              <div class="footer_black_prepare_item">
                <a @click="softwareDown = true">軟體下載安裝</a>
              </div>
              <div class="footer_black_prepare_item">
                <a href="https://www.speedtest.net/zh-Hans" target="new_blank"
                  >網速測試</a
                >
              </div>
            </div>
          </div>
          <div class="footer_black_contact">
            <div class="footer_black_contact_title">聯絡資訊</div>
            <div class="footer_black_contact_text">
              <div class="footer_contact_text">
                客服時間：週一至週五&nbsp;下午2:00至晚上9:00
              </div>
            </div>
            <div class="footer_black_contact_text">
              <div class="footer_contact_text">客服電話：(02)2311-3260</div>
            </div>
            <div class="footer_black_contact_text">
              <div class="footer_contact_text">
                官方line@帳號：&nbsp;<a
                  href="https://lin.ee/aeJnRs5"
                  target="_blank"
                  >@istudy&nbsp;好師到雲端學苑</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="footer_black_bottom">
          <div class="footer_black_copyright">
            <span>學習王科技股份有限公司 版權所有</span>
            <span
              >Copyright © Study123 Technology Co.Ltd. All Rights
              Reserved.</span
            >
          </div>
          <div class="footer_black_terms">
            <a class="terms_of_service" @click="serviceItem = true">服務條款</a>
            <a class="privacy_policy" @click="privacyRule = true">隱私權聲明</a>
          </div>
        </div>
      </div>
    </div>
    <!-- ... footer 聯絡資訊、追蹤我們 招募人才 版權說明 條款 聲明 黑色區塊 end... -->

    <!-- ... pup視窗-上課影音 ... -->
    <div class="pup_video_play_container" style="display: none">
      <div class="member_pup_bg"></div>
      <div class="pup_video_play">
        <img
          src="../../assets/image/pup_video_close.png"
          class="pup_video_close"
          alt
        />
        <img
          src="../../assets/image/video_cover.jpg"
          class="pup_video_cover"
          alt
        />
        <div class="pup_video_play_iframe"></div>
      </div>
    </div>
    <!-- ... pup視窗-上課影音end ... -->
    <!-- ... pup視窗-軟體下載安裝 ... -->
    <div class="software_download_setting" v-if="softwareDown">
      <div class="member_pup_bg" @click="softwareDown = false"></div>
      <div class="member_pup_container">
        <div class="member_pup_title">軟體下載安裝</div>

        <div class="software_detail_container" style="margin-top: 30px">
          <div id="soft1">
            <div class="software_step_container">
              <div class="software_step_text">
                步驟1：前往Zoom(視訊軟體)網址下載
              </div>
              <div class="software_step_text">
                <a
                  href="https://zoomnow.net/zntw_zoom_download.php?showType=ALL"
                  target="_blank"
                  >點此至下載網址</a
                >
              </div>
            </div>
            <div class="software_step_container">
              <div class="software_step_text">
                步驟2：選擇您通訊載具系統版本點擊下載
              </div>
              <div class="software_step_pic">
                <img
                  src="../../assets/image/setting_pic/zoom_setting_1.jpg"
                  alt=""
                />
              </div>
              <div class="software_step_tip" style="color: #ff0000">
                使用手機、平板上課時會可能有文字過小無法清晰閱讀以及功能受限的狀況，建議使用桌上型電腦或筆記型電腦進行上課。
              </div>
            </div>
            <div class="software_step_infor">
              接續安裝步驟為window電腦版本，其他系統安裝檔案流程可依系統提示依序進行，如有疑問可網路搜尋相關教學或聯絡客服人員。
            </div>
            <div class="software_step_container">
              <div class="software_step_text">步驟3：儲存zoom安裝檔</div>
              <div class="software_step_pic">
                <img
                  src="../../assets/image/setting_pic/zoom_setting_2.jpg"
                  alt=""
                />
              </div>
            </div>
            <div class="software_step_container">
              <div class="software_step_text">
                步驟4：安裝檔下載完成後，點擊安裝檔進行安裝
              </div>
              <div class="software_step_pic">
                <img
                  src="../../assets/image/setting_pic/zoom_setting_3.jpg"
                  alt=""
                />
              </div>
            </div>
            <div class="software_step_container">
              <div class="software_step_text">
                步驟5：點選「是」執行zoom安裝檔
              </div>
              <div class="software_step_pic">
                <img
                  src="../../assets/image/setting_pic/zoom_setting_4.jpg"
                  alt=""
                />
              </div>
            </div>
            <div class="software_step_container">
              <div class="software_step_text">
                步驟6：點選「是」允許zoom應用程式
              </div>
              <div class="software_step_pic">
                <img
                  src="../../assets/image/setting_pic/zoom_setting_5.jpg"
                  alt=""
                />
              </div>
            </div>
            <div class="software_step_container">
              <div class="software_step_text">步驟7：等候安裝進度完成</div>
              <div class="software_step_pic">
                <img
                  src="../../assets/image/setting_pic/zoom_setting_6.jpg"
                  alt=""
                />
              </div>
            </div>
            <div class="software_step_container">
              <div class="software_step_text">
                步驟8：安裝完成後可在工作列中滑鼠右鍵點擊zoom圖示，選擇釘選至工作列，以便下次開啟使用
              </div>
              <div class="software_step_pic">
                <img
                  src="../../assets/image/setting_pic/zoom_setting_7.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <img
          alt=""
          class="member_pup_close"
          @click="softwareDown = false"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>
    <!-- ... pup視窗-軟體下載安裝end ... -->
    <!-- ... pup視窗-服務條款 ... -->
    <div class="notice_pup_rule sevice_rule" v-if="serviceItem">
      <div class="notice_pup_bg" @click="serviceItem = false"></div>
      <div class="notice_pup_container">
        <div class="notice_pup_title">服務條款</div>
        <div class="notice_pup_main">
          歡迎您來到《好師到雲端學苑》（以下稱本網站），當您使用本網站時，即表示您已閱讀、瞭解並同意接受本網站之所有服務聲明之內容，包含服務條款、著作權聲明、免責聲明、隱私權聲明(以下簡稱本網聲明)，您於任何修改或變更後繼續使用本網站，也視為您已閱讀、瞭解並同意接受該等修改或變更。<br />
          <br />
          一、網站使用規則<br />
          有關現有或將來之各項使用均受本服務條款之規範。<br />
          使用者在使用過程中，必須遵循以下原則，否則本公司有權不經通知逕行暫停、取消或終止全部或部分會員服務：<br />
          1. 遵守當地有關的法律和法規。<br />
          2. 不得為任何非法目的而使用。<br />
          3. 遵守所有與網路服務有關的網路協定、規定。<br />
          4. 不得利用本網站服務系統進行任何不利於本網站的行為。<br />
          5. 如發現任何非法使用情況，應立即通告本網站。<br />
          <br />
          二、有關行銷廣告<br />
          1.
          本網站所傳播或刊載之行銷廣告，關於其內容所展現的文字、圖片、動畫、聲音、影像或以其他方式對商品或服務之說明，都是由合作商所提供，對於廣告之內容，本網站基於尊重廣告主或廣告代理商權利，不對行銷廣告之內容進行審查。您應該自行判斷該廣告的正確性及可信度，本網站不對廣告做任何擔保責任。<br />
          2.
          本網站不定時會與其他公司、廠商等第三人（「內容提供者」）合作，由其提供包括訊息、電子報、eDM等不同內容供本網站刊登，本網站於刊登時均將註明內容提供者。基於尊重內容提供者之智慧財產權，本網站對其所提供之內容並不做修改，對該等內容之正確真偽亦不負任何責任。<br />
          3.
          凡經註冊成為本網站會員後，即視為同意接收本網站日後所發出之會員電子報及EDM訊息。<br />
          <br />
          三、網站服務暫停、中斷或終止<br />
          本網站於下列情形之一時，有權隨時暫停、中斷或終止服務之全部或一部，對於使用者不負擔任何賠償責任：<br />
          1. 對於本網站相關系統設備進行遷移、更換或升級維護時。<br />
          2. 因不可歸責於本網站所造成服務停止或中斷。<br />
          3. 因不可抗力所造成服務停止或中斷。<br />
          <br />
          四、與第三人網站之連結<br />
          本網站連結之搜尋引擎及地圖服務，僅在提供使用者方便且迅速查詢到使用者想要的資訊，您所查到的資料是使用搜尋引擎之結果，不代表本網站與連結網站之業者有任何合作關係。如您因到達該網站而產生之糾紛或損害，請您逕行與該網站之業者聯絡，本網站不負任何責任。<br />
          <br />
          五、違約賠償<br />
          使用者同意保障和維護本網站及其他第三方的權益，如因違反有關法律、法規或本約定項下的任何條款，而對本網站或任何第三人造成損失，使用者同意承擔由此造成的損害賠償責任。<br />
          <br />
          六、服務聲明之增訂及修改<br />
          本網站之所有服務聲明條款如有增訂或修改，您同意自該修訂條款於本網站公告之時起受其拘束，本網站將不個別通知。如您於公告後繼續使用本網站，則視為您已經同意該修訂條款。<br />
          <br />
          七、準據法及管轄法院<br />
          1. 關於本服務條款之解釋或適用，以中華民國之法律為準據法。<br />
          2.
          使用者因使用本網站而生之爭議，同意本誠信原則解決之，如訴訟之必要時，同意以台灣台北地方法院為第一審管轄法院。<br />
          <br />
          八、其他規定<br />
          1.
          如本服務條款無論因何種原因完全或部分無效或不具有執行力，本條款的其餘條款仍應有效並且有約束力。<br />
          2. 本條款中的標題僅為方便而設，在解釋本條款時應被忽略。<br />
        </div>
        <img
          alt
          class="notice_pup_close"
          @click="serviceItem = false"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>
    <!-- ... pup視窗-服務條款end ... -->
    <!-- ... pup視窗-隱私權聲明 ... -->
    <div class="notice_pup_rule right_to_privacy_rule" v-if="privacyRule">
      <div class="notice_pup_bg" @click="privacyRule = false"></div>
      <div class="notice_pup_container">
        <div class="notice_pup_title">隱私權聲明</div>
        <div class="notice_pup_main">
          學習王科技非常重視會員的隱私權且遵循「個人資料保護法」之規定，因此制訂了隱私權保護政策，
          您可參考下列隱私權保護政策的內容。<br />
          <br />
          當您登錄或提供或相關之給予方式將個人資料登錄或給予 「好師到雲端學苑」
          網站(以下簡稱myteacher)上時，即代表您已經同意並充分明白我們的隱私權政策。<br />
          <br />
          1、
          我們的隱私權政策適用於您在「myteacher」之網站上所涉及的個人資料。<br />
          2、 隱私權政策適用於「myteacher」之所有使用者。<br />
          3、
          我們會保留您來到本網瀏覽或查詢時所自動產生的相關記錄，例如：IP、Cookie、時間、使用的瀏覽器及點選資料記錄等相關資訊。<br />
          4、
          除具司法單位正式發函之公文，並由檢調人員至場，否則我們無法提供相關任何紀錄給任何人員或單位。<br />
          5、
          「myteacher」為提昇對您的服務品質，會使用Cookies的方式在您的電腦中放置一文字檔，以便盡量提供您貼心與專業的個人化服務。<br />
          6、
          當您註冊或使用我們的任何服務時，您自行填寫的個人資料，比如像姓名、電子郵件、生日、性別、電話等等相關個人資訊我們將加以存檔。<br />
          7、
          我們在與其他公司合作、結盟、聯盟、併購或被併購、政府或司法機關要求時，將依相關法令規定提供您的個人資料與其他公司。<br />
          8、
          除上述第七條外，我們無權向任何單位出借、交換、販賣的您個人資料。<br />
          9、
          「myteacher」會尊重並保障您的資料隱私及安全，所有您的相關資料將會受到妥善的保護；但您在網站討論區、電子郵件等訊息所主動公開發表的個人資料，極有可能被其
          他第三者知悉或盜用，請您慎思。<br />
          10、
          請妥善保管您的密碼及或任何個人資料，不要將任何個人資料，尤其是密碼提供給任何人。在您讀取電子郵件後，務必記得登出帳戶。若您是與他人共享電腦或使用公
          共電腦，切記要關閉瀏覽器視窗，以防止他人讀取您的個人資料或信件。<br />
          11、 我們會隨法令或政策適時修正我們的隱私權政策，敬請回閱。<br />
          12、 當您對隱私權政策有疑慮或建議時，請email 至
          anyaffairs@gmail.com告訴我們，謝謝您。<br />
        </div>
        <img
          alt
          class="notice_pup_close"
          @click="privacyRule = false"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>
    <!-- ... pup視窗-隱私權聲明end ... -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      softId: 0,
      softwareDown: false,
      serviceItem: false,
      privacyRule: false,
    };
  },
  methods: {
    turnTosoft(id) {
      return (this.softId = id);
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/member_center.css";
@import "../../assets/css/clndr.css";
</style>